export const FS = {
  xs: "0.5rem",
  sm: "0.75rem",
  md: "1rem",
  lg: "1.25rem",
  xl: "1.5rem",
  "2xl": "2rem",
  "3xl": "2.5rem",
  "4xl": "3rem",
  "5xl": "3.5rem",
  "6xl": "4rem",
  "7xl": "4.5rem",
  "8xl": "5rem",
  "9xl": "5.5rem",
  "10xl": "6rem",
  "11xl": "6.5rem",
  "12xl": "7rem",
  "13xl": "7.5rem",
  "14xl": "8rem",
  "15xl": "8.5rem",
  "16xl": "9rem",
  "17xl": "9.5rem",
  "18xl": "10rem",
  "19xl": "10.5rem",
  "20xl": "11rem",
};
